<template>
	<div>
		<v-row> 
			<v-col>
				<div class="title">Informazioni utili su chi contattare in caso di assistenza.</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="6">
				<v-card color="tableheader">
					<v-card-title>Riferimenti utili per supportare il cliente</v-card-title>
					<v-card-text>
						<v-simple-table>
							<template v-slot:default>
								<tbody>
									<tr>
										<th class="text-left">sito</th>
										<td class="text-right">http://www.skoda-italia.it</td>
									</tr>
									<tr>
										<th class="text-left">nr.verde</th>
										<td class="text-right">800 100 600</td>
									</tr>
									<tr>
										<th class="text-left">sms richiesta saldo punti</th>
										<td class="text-right">346 3630100</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="6">
				<v-card color="tableheader">
					<v-card-title>Servizio Clienti Škoda</v-card-title>
					<v-card-subtitle>richieste/segnalazioni dei clienti</v-card-subtitle>
					<v-card-text>
						<v-simple-table>
							<template v-slot:default>
								<tbody>
									<tr>
										<th class="text-left">numero verde</th>
										<td class="text-right">
											800 100 600<br>
											<small>dal lunedì al venerdì<br>dalle 8:30 alle 19:30</small>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="6">
				<v-card color="tableheader">
					<v-card-title>Studium Group srl</v-card-title>
					<v-card-subtitle>informazioni o segnalazioni per il sito web</v-card-subtitle>
					<v-card-text>
						<v-simple-table>
							<template v-slot:default>
								<tbody>
									<tr>
										<th class="text-left">telefono</th>
										<td class="text-right">
											045 6704568<br>
											<small>dal lunedì al venerdì<br>dalle 9:00 alle 13:00<br>dalle 14:00 alle 17:00</small>
										</td>
									</tr>
									<tr>
										<th class="text-left">email</th>
										<td class="text-right">skodasupercard@servicedirect.it	</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>

		</v-row>
	</div>
</template>

<script>
/**********************************************************************************************
**** ---- ****************************************************************************
***********************************************************************************************
* 
*/

//
export default {
	name: 'ServiziHelpdesk',
	components: {  },
	props: {
		// v-model
		value: {
			type: Object,
			required: false,
			default: () => {return {}}
		},
	},
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		darkTheme() { return this.$vuetify.theme.dark },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>
 