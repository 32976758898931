<template>
	<standard-container menu-id="servizi">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="$isAuthorizedTo('servizi.archivio')" key="tabServiziArchivio">
					Archivio
				</v-tab>
				<v-tab v-if="$isAuthorizedTo('servizi.circolari')" key="tabServiziCircolari">
					Circolari
				</v-tab>
				<v-tab v-if="$isAuthorizedTo('servizi.help')" key="tabServiziHelp">
					Helpdesk
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!-- archivio -->
			<v-tab-item v-if="$isAuthorizedTo('servizi.archivio')" key="tabServiziArchivio" eager class="ma-1">
				<servizi-archivio></servizi-archivio>
			</v-tab-item>
			<!-- circolari -->
			<v-tab-item v-if="$isAuthorizedTo('servizi.circolari')" key="tabServiziCircolari" eager class="ma-1">
				<servizi-circolari></servizi-circolari>
			</v-tab-item>
			<!-- help desk -->
			<v-tab-item v-if="$isAuthorizedTo('servizi.help')" key="tabServiziHelp" class="ma-1">
				<servizi-help></servizi-help>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Servizi ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
import ServiziArchivio from '@/views/Servizi/servizi-archivio'
import ServiziCircolari from '@/views/Servizi/servizi-circolari'
import ServiziHelp from '@/views/Servizi/servizi-help'
export default {
	name: 'ServiziPage',
	components: { StandardContainer,ServiziArchivio ,ServiziCircolari,ServiziHelp},
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>